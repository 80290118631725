const style = {
  commentBox: {
    width:'80vw',
    margin:'0 auto',
  },
  title: {
    textAlign:'center',
    textTransform:'uppercase'
  },
  commentButton: {
    background: 'url("./static/commentButtonBG.jpg") repeat scroll 0 0 transparent',
    height: '50px',
    borderRadius: '25px',
    padding: '5px 10px',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  commentFormModal: {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      maxWidth: '90vw',
      maxHeight: '80vh',
    }
  },
  modalButton: {
    position: 'relative',
    top: '0',
    right: '0',
    fontSize: '40px',
  },
  commentForm: {
    margin:'10px',
    display:'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateRows: 'auto',
    gridColumnGap: '10px',
    gridRowGap: '15px',
  },
  commentFormAuthor: {
    gridColumn: 'span 6',
    borderRadius:'3px',
    height: '40px',
  },
  commentFormText: {
    gridColumn: 'span 11',
    borderRadius: '3px',
    borderColor: 'grey',
    borderStle: 'inset',
    resize: 'none',
    height: '200px',
  },
  commentFormImage: {
    gridColumn: 'span 2',
  },
  commentFormPost: {
    height: '70px',
    gridColumn: 'span 4',
    margin:'5px 3px',
    fontSize:'1.5rem',
    borderRadius:'3px',
    color:'#fff',
    textTransform:'uppercase',
    letterSpacing:'.055rem',
    border:'none',
    gridColumnEnd: '12',
  },
  updateLink: {
    textDecoration:'none',
    paddingRight:'15px',
    fontSize:'.7rem'
  },
  deleteLink: {
    textDecoration:'none',
    paddingRight:'15px',
    fontSize:'.7rem',
    color:'red'
  },
  welcomeAvatar: {
    maxWidth:'200px',
    maxHeight:'200px',
    margin: 'auto',
    display:'block',
    position: 'relative',
    top: '-80px',
  },
  welcomeCard: {
    position: 'relative',
    top: '-80px',
    margin: '0 10px 20px 0',
    fontSize: '18px',
  },
  chairCard: {
    margin: '10px',
    fontSize: '18px',
  }
}

module.exports = style;
