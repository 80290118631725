import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import Comment from './Comment';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 40px;
`

const CommentList = props => {
  const { data, fetchFunction, isMoreComments } = props;

  const commentNodes = data.map(comment =>
      <Comment author={ comment.author } picture = { comment.picture} date = {comment.date} key={ comment._id }>
        { comment.text }
      </Comment>)

  return (
    <Container>
      <InfiniteScroll
          dataLength={data.length}
          next={fetchFunction}
          hasMore={isMoreComments}
          loader={<div style={{textAlign: 'center'}}><h4>Loading...</h4></div>}
          endMessage={<div style={{textAlign:'center'}}>&#x1F49D; Thats all the memories for now &#x1F49D;</div>}
        >
        { commentNodes }
      </InfiniteScroll>
    </Container>
  )
}

export default CommentList;
