import React, { Component } from 'react';
import style from './style';

const chairUrl = `${process.env.PUBLIC_URL}/static/chair.jpg`;

class Chair extends Component {
  render() {
    return(
      <div>
        <div className="chair-header"></div>
        <div className="row">
            <div className="col-xs-8 col-xs-offset-2 welcome-card">
            <div style={style.chairCard}>
            <h2 style={{textAlign: 'center'}}>Her Chair</h2>
              <p>
                A memorial seat will be placed in honour of Ashley's life on Dairy Farmer's Hill at the National Arboretum in July 2018.
                Hopefully this will provide people with a place to go whenever they would like a quiet place to connect or reflect.
              </p>
              <img src={chairUrl} className="img-circle img-responsive center-block" ></img>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Chair;