import React, { Component } from 'react';
import style from './style';

const avatar_url = `${process.env.PUBLIC_URL}/static/avatar.jpg`;

class Welcome extends Component {
  render() {
    return(
      <div>
        <div className="row welcome-header">
        </div>
        <div className="row">
            <div className="col-xs-8 col-xs-offset-2 welcome-card">
            <img src={avatar_url} className="img-circle" style={style.welcomeAvatar} ></img>
            <div style={style.welcomeCard}>
              <h2 style={{textAlign: 'center'}}>Welcome</h2>
              <p>
                Our beautiful Ash passed on 26/01/2018 due to a tragic accident. This place has been made to share memories, feelings and appreciation for Ash,
                 so that the gifts of love laughter and happiness she provided to us during her life may not be blurred by time or distance.
                 <br/> <br/> 
                This is a place to be used in whichever way feels best for you...
                <ul>
                  <li>You can leave a post either anonymously or with a name</li>
                  <li>You can share memories or pictures</li>
                  <li>You can write to Ashley</li>
                  <li>Or you can simply come and read what others have said and reflect</li>
                </ul>

                 
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Welcome;