import React, { Component } from 'react';
import ImagesUploader from 'react-images-uploader';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import style from './style';

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      author: '', 
      text: '', 
      picture: '',
      formValid: false,
      uploadingImage: false,
    };
    this.handleAuthorChange = this.handleAuthorChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePictureUpload = this.handlePictureUpload.bind(this);
  }

  handleAuthorChange(e) {
    this.setState({ author: e.target.value });
  }

  handleTextChange(e) {
    const { formValid } = this.state; 
    this.setState({ text: e.target.value });

    this.validateForm();
  }

  validateForm() {
    if ( this.state.text !== '' && this.state.uploadingImage === false) {
      this.setState({ formValid: true});
    } else {
      this.setState({ formValid: false});
    }
  }

  handlePictureUpload(picture) {
    this.setState({ picture });
  }

  handleSubmit(e) {
    e.preventDefault();
    let author = this.state.author.trim();
    let text = this.state.text.trim();
    let picture = this.state.picture;
    if (!text) {
      return;
    }
    this.props.onCommentSubmit({ author: author, text: text, picture: picture });
    this.setState({ author: '', text: '', picture: '', formValid: false });
  }

  render() {
    return (
      <form style={ style.commentForm } onSubmit={ this.handleSubmit }>
        <input
          type='text'
          placeholder='Anonymous'
          style={ style.commentFormAuthor}
          value={ this.state.author }
          onChange={ this.handleAuthorChange } />
        <textarea
          type='text'
          maxLength='5000'
          placeholder='Say something...'
          style={ style.commentFormText}
          value={ this.state.text }
          onChange={ this.handleTextChange } />
        <div           
          style={ style.commentFormImage}>
          <ImagesUploader
            url={`${process.env.PUBLIC_URL}/api/picture`}
            optimisticPreviews
            onLoadStart={ () => { 
              this.setState({uploadingImage:true});
              this.validateForm();
             } }
            onLoadEnd={ (err, picture) => { 
              if (err) console.error(err);
              this.setState({uploadingImage:false});
              this.setState({picture:picture});
              this.validateForm();
            } }
            multiple={false}
            label="Upload image"
            onChange={this.handlePictureUpload}
          />
        </div>
        <input
          type='submit'
          className='btn btn-primary'
          style={ style.commentFormPost }
          value='Post'
          disabled={ !this.state.formValid } />
      </form>
    )
  }
}

export default CommentForm;
