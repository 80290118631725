import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import cloudinaryUrl from 'cloudinary-microurl';
import marked from 'marked';
import styled from 'styled-components';
import { format } from 'date-fns';

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: url("./static/memoryTexture.jpg") repeat scroll 0 0 transparent;
  margin: 10px;
  padding: 15px 15px;
  font-size:  1.4rem;
  border-radius: 25px;
  opacity: 0.8;
`

const BaseHeader = styled.span`
  line-height: 1.2;
  font-size: 14px;
  margin-bottom: 10px;
  @media(min-width: 768px) {
    font-size: 18px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Author = styled(BaseHeader)`
  flex-basis: 100%;
  @media(min-width: 768px) {
    flex-basis: auto;
  }
`

const Date = styled(BaseHeader)`
  align-self: flex-end;
  font-size: 10px;
  @media(min-width: 768px) {
    font-size: 16px;
  }
`

const Content = styled.span`
  font-size: 12px;
  @media(min-width: 768px) {
    font-size: 16px;
  }
`;

const Image = styled.img`
  max-height: 100px;
  max-width: 100px;
  cursor: pointer;
`;

class Comment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  
  }

  rawMarkup() {
    let rawMarkup = marked(this.props.children.toString());
    return { __html: rawMarkup };
  }
  render() {
    const { author, date, picture } = this.props
    const { photoIndex, isOpen } = this.state;

    const imageThumbnail = cloudinaryUrl(this.props.picture, {
      secure: true,
      cloud_name: 'ashmemories',
      quality: 30
    });

    const image = cloudinaryUrl(this.props.picture, {
      secure: true,
      cloud_name: 'ashmemories',
      quality: 100
    });

    const images = [image];

    return (
      <CommentContainer>
        <Header>
          <Author>{author}</Author>
          <Date>{format(date, 'Do MMMM YYYY')}</Date>
        </Header>
        <Content dangerouslySetInnerHTML={ this.rawMarkup() } />
        { picture && picture.length > 0 &&
          <Image src={ imageThumbnail } onClick={() => this.setState({ isOpen: true })}/>
        }

      {isOpen && picture && picture.length > 0 &&  (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => this.setState({ isOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length,
            })
          }
        />
      )}
      </CommentContainer>
    )
  }
}

export default Comment;
