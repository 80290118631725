import React, { Component } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import style from './style';
import Memories from './Memories';
import Navigation from './Navigation2';
import Welcome from './Welcome'
import Chair from './Chair'


class App extends Component {
  render() {
    return(
      <div className="Container">
        <Router>
          <div>
            <Navigation />
            <Route exact path="/" component={Welcome}/>
            <Route path="/wall" component={Memories}/>
            <Route path="/chair" component={Chair}/>
          </div>
        </Router>
      </div>
    )
  }
}

export default App;