import React, { Component } from 'react';
import CommentBox from './CommentBox';

class Memories extends Component {
  render() {
    return(
      <div>
        <h2 style={{textAlign: 'center'}}>Ash's Wall</h2>
        <CommentBox url='/api/comments' pollInterval={10000}/>
      </div>
    )
  }
}

export default Memories;